body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pf-c-login__header .pf-c-brand {
  width: 50% !important;
}

/* .pf-c-login__header {
  text-align: center;
} */

/* @media (min-width: 576px),(min-width: 768px),(min-width: 992px),(min-width: 1200px){
} */
/* @media (min-width: 1200px) {
  .pf-c-login__header {
    text-align: start !important;
  }
} */

main#main-content {
  width: 100% !important;
  height: calc(100vh - 70px) !important;
  overflow: auto;
  overflow-x: hidden;
}